// UsedTags.jsx

import React, { useState, useEffect, useMemo } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    CardTitle,
    Label,
    Input,
    Row,
} from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactPaginate from 'react-paginate';
import { ThreeCircles } from 'react-loader-spinner';
import Flatpickr from "react-flatpickr";
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';
import { serverTimestamp, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getDownloadURL, uploadBytesResumable, getStorage, ref } from "firebase/storage";

const UsedTags = () => {
    
    const storage = getStorage();

    // ---------------------------
    // STATE VARIABLES
    // ---------------------------
    const [initialLoad, setInitialLoad] = useState(true);
    const [usedTagsList, setUsedTagsList] = useState([]);
    const [allTagPrograms, setAllTagPrograms] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [allSpecies, setAllSpecies] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [fetchedData, setFetchedData] = useState(false);

    // Action buttons states
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isRestoringUsed, setIsRestoringUsed] = useState(false);

    const [updateTagId, setUpdateTagId] = useState('');
    const [updateTagIndex, setUpdateTagIndex] = useState(null);
    const [deleteTagId, setDeleteTagId] = useState('');
    const [deleteTagIndex, setDeleteTagIndex] = useState(null);
    const [restoreTagId, setRestoreTagId] = useState('');
    const [restoreTagIndex, setRestoreTagIndex] = useState(null);

    // Modal Visibility States
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [restoreUsedModal, setRestoreUsedModal] = useState(false);
    const [addModal, setAddModal] = useState(false);

    // Uploaded Images (if applicable)
    const [updatedImage, setUpdatedImage] = useState(null);

    // References from session storage
    const userData = JSON.parse(localStorage.getItem("userData"));
    const programData = JSON.parse(localStorage.getItem("programData"));
    const programRef = firebase.firestore().collection('programs').doc(programData.id);

    // ---------------------------
    // DOCUMENT TITLE
    // ---------------------------
    useEffect(() => {
        document.title = "Used Tags | Fish-Trak - Partner's Dashboard";
    }, []);

    // ---------------------------
    // FETCH DATA FUNCTIONS
    // ---------------------------

    // Fetch Tag Programs
    const fetchTagPrograms = () => {
        firebase
            .firestore()
            .collection('programs')
            .orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const newProgramsList = [];
                    querySnapshot.forEach(doc => {
                        const programItem = doc.data();
                        programItem.id = doc.id;

                        newProgramsList.push(programItem);
                    });
                    setAllTagPrograms(newProgramsList);
                },
                error => {
                    console.error("Error fetching tag programs:", error);
                    toast.error("Error fetching tag programs.");
                }
            );
    };

    // Fetch All Users
    const fetchAllUsers = () => {
        firebase
            .firestore()
            .collection('users')
            .orderBy('created_at', 'desc')
            .onSnapshot(
                querySnapshot => {
                    const newUsersList = [];
                    querySnapshot.forEach(doc => {
                        const userItem = doc.data();
                        userItem.id = doc.id;

                        userItem.name = `${userItem.first_name} ${userItem.last_name}`;
                        userItem.email = userItem.email_address;
                        userItem.port = userItem.home_port;
                        userItem.count = userItem.catch_count;

                        const createdAt = userItem?.created_at?.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                        userItem.created = createdAt;
                        const updatedAt = userItem?.updated_at?.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                        userItem.updated = updatedAt;

                        userItem.status = userItem.active ? 'Active' : 'Disabled';

                        newUsersList.push(userItem);
                    });
                    setAllUsersList(newUsersList);
                },
                error => {
                    console.error("Error fetching users:", error);
                    toast.error("Error fetching users.");
                }
            );
    };

    // Fetch All Species
    const fetchAllSpecies = () => {
        firebase
            .firestore()
            .collection('species')
            .orderBy('name', 'asc')
            .onSnapshot(
                querySnapshot => {
                    const newAllSpecies = [];
                    querySnapshot.forEach(doc => {
                        const speciesItem = doc.data();
                        speciesItem.id = doc.id;
                        newAllSpecies.push(speciesItem);
                    });
                    setAllSpecies(newAllSpecies);
                },
                error => {
                    console.error("Error fetching species:", error);
                    toast.error("Error fetching species.");
                }
            );
    };

    // Fetch Used Fish Tags with Filters
    const fetchUsedFishTags = () => {
        let newQuery = firebase.firestore()
            .collection('tags')
            .where('tag_program', '==', programRef)
            .where('used', '==', true)
            .orderBy('created_at', 'desc');

        // Apply Species Filter
        if (selectedSpeciesFilter) {
            const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
            newQuery = newQuery.where('fish_species', '==', speciesRef);
        }

        // Apply Date Filters
        if (startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }
        else if (startDateFilter && !endDateFilter) {
            newQuery = newQuery
                .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
        }
        else if (!startDateFilter && endDateFilter) {
            newQuery = newQuery
                .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
        }

        newQuery.onSnapshot(
            (querySnapshot) => {
                const newTagsList = [];
                querySnapshot.forEach((doc) => {
                    const tagItem = doc.data();
                    const tagId = doc.id;
                    tagItem.id = tagId;

                    tagItem.tag = `#${tagItem.tag_number}`;
                    tagItem.program = tagItem.program_name;
                    const createdAt = tagItem?.created_at?.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                    tagItem.created = createdAt;
                    const updatedAt = tagItem?.updated_at?.toDate().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) || '';
                    tagItem.updated = updatedAt;
                    tagItem.link = `/tag/details/${tagId}`;
                    tagItem.userId = tagItem.first_angler?.id || '';
                    tagItem.user_link = tagItem.userId ? `/user/details/${tagItem.userId}` : '';
                    tagItem.speciesId = tagItem.fish_species?.id || '';
                    tagItem.species_link = tagItem.speciesId ? `/species/details/${tagItem.speciesId}` : '';
                    tagItem.program_link = `/program/details/${tagItem.tag_program.id}`;

                    newTagsList.push(tagItem);
                });
                setUsedTagsList(newTagsList);
                setIsUploading(false);
                setIsUpdating(false);
                setIsDeleting(false);
                setIsRestoringUsed(false);
                setFetchedData(true);
            },
            (error) => {
                console.error("Error fetching used tags:", error);
                toast.error("Error fetching used tags.");
            }
        );
    };

    // ---------------------------
    // INITIAL DATA FETCHING
    // ---------------------------
    useEffect(() => {
        if (initialLoad) {
            setInitialLoad(false);
            fetchAllSpecies();
            fetchAllUsers();
            fetchUsedFishTags();
            fetchTagPrograms();
        }

        if (fetchedData && usedTagsList.length > 0) {
            setFetchedData(false);
            // No need to initialize list.js
        }
    }, [initialLoad, fetchedData, usedTagsList.length]);

    // ---------------------------
    // SEARCH AND FILTER LOGIC
    // ---------------------------
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSpeciesFilter, setSelectedSpeciesFilter] = useState('');
    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);

    const filteredTags = useMemo(() => {
        return usedTagsList.filter(tag => {
            const tagMatch = tag.tag.toLowerCase().includes(searchQuery.toLowerCase());
            const anglerMatch = tag.user_link.toLowerCase().includes(searchQuery.toLowerCase());
            const speciesMatch = tag.species_link.toLowerCase().includes(searchQuery.toLowerCase());
            return tagMatch || anglerMatch || speciesMatch;
        });
    }, [usedTagsList, searchQuery]);

    // ---------------------------
    // PAGINATION CALCULATIONS
    // ---------------------------
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const pageCount = Math.ceil(filteredTags.length / itemsPerPage);
    const displayedTags = filteredTags.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    // ---------------------------
    // HANDLE PAGE CHANGE
    // ---------------------------
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // ---------------------------
    // HANDLE APPLY FILTERS BUTTON
    // ---------------------------
    const handleApplyFilters = () => {
        fetchUsedFishTags();
        setCurrentPage(0); // Reset to first page when filters are applied
    };

    // ---------------------------
    // HANDLE DOWNLOAD CSV
    // ---------------------------
    const handleDownloadCSV = async () => {
        try {
            let csvQuery = firebase.firestore()
                .collection('tags')
                .where('tag_program', '==', programRef)
                .where('used', '==', true)
                .orderBy('created_at', 'desc');

            // Apply Species Filter
            if (selectedSpeciesFilter) {
                const speciesRef = firebase.firestore().collection('species').doc(selectedSpeciesFilter);
                csvQuery = csvQuery.where('fish_species', '==', speciesRef);
            }

            // Apply Date Filters
            if (startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)))
                    .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }
            else if (startDateFilter && !endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '>=', firebase.firestore.Timestamp.fromDate(new Date(startDateFilter)));
            }
            else if (!startDateFilter && endDateFilter) {
                csvQuery = csvQuery
                    .where('created_at', '<=', firebase.firestore.Timestamp.fromDate(new Date(endDateFilter)));
            }

            const snapshot = await csvQuery.get();
            const csvRows = [];

            // CSV header
            csvRows.push([
                'Tag ID',
                'Tag Number',
                'First Angler ID',
                'Fish Species ID',
                'Created Date',
                'Created Time',
                'Updated Date',
                'Updated Time',
                'Catches Count',
                'Used',
                'Deleted',
                'Program Name'
            ].join(','));

            snapshot.forEach((doc) => {
                const data = doc.data();
                const row = [
                    doc.id,
                    (data.tag_number || '').replace(/,/g, ''),
                    data.first_angler ? data.first_angler.id : '',
                    data.fish_species ? data.fish_species.id : '',
                    data.created_at ? data.created_at.toDate().toLocaleDateString() : '',
                    data.updated_at ? data.updated_at.toDate().toLocaleDateString() : '',
                    data.catch_count || 0,
                    data.used ? 'Yes' : 'No',
                    data.deleted ? 'Yes' : 'No',
                    (data.program_name || '').replace(/,/g, '')
                ];
                csvRows.push(row.join(','));
            });

            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'used_tags.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error generating CSV:', error);
            toast.error('Could not download CSV. Please try again.');
        }
    };

    // ---------------------------
    // CRUD OPERATIONS
    // ---------------------------

    // Handle Create Tag Form Submission
    const handleCreateTag = (event) => {
        event.preventDefault();
        submitCreateForm(event);
    };

    const submitCreateForm = async (event) => {
        setIsUploading(true);
        event.preventDefault();

        try {
            const userId = event.target.first_user.value;

            const tagData = {
                created_at: serverTimestamp(),
                updated_at: serverTimestamp(),
                tag_number: event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, ''),
                catch_count: 0,
                used: false,
                deleted: false,
                program_image: programData.image_url,
                program_name: programData.program_name,
                tag_program: programRef,
                first_angler: firebase.firestore().collection('users').doc(userId),
            };

            // Create new tag in Firestore
            await firebase.firestore().collection('tags').add(tagData);

            toast.success('The new fish tag has been created successfully!');
            fetchUsedFishTags();
            setAddModal(false);
            setIsUploading(false);
        } catch (error) {
            console.error("Error creating fish tag:", error);
            setIsUploading(false);
            toast.error('Could not create fish tag entry. Please try again.');
        }
    };

    // Handle Update Tag Form Submission
    const handleUpdateTag = (event) => {
        event.preventDefault();
        submitUpdateForm(event);
    };

    const submitUpdateForm = async (event) => {
        setIsUpdating(true);
        event.preventDefault();

        try {
            const tagRef = firebase.firestore().collection('tags').doc(updateTagId);
            const programId = event.target.tag_program.value;
            const programIndex = allTagPrograms.findIndex(program => program.id === programId);
            const userId = event.target.first_angler.value;

            const tagData = {
                updated_at: serverTimestamp(),
            };

            const actualTag = usedTagsList[updateTagIndex];

            // Update Tag Number if changed
            if (event.target.tag_number.value !== actualTag.tag_number) {
                tagData['tag_number'] = event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '');
                tagData['tag'] = `#${event.target.tag_number.value.replace(/[^0-9a-zA-Z-+.:@%&#, ]/g, '')}`;
            }

            // Update Assigned User if changed
            if (userId !== actualTag.first_angler.id) {
                tagData['first_angler'] = firebase.firestore().collection('users').doc(userId);
                tagData['angler_name'] = allUsersList.find(user => user.id === userId)?.name || '';
            }

            // Update Tag Program if changed
            if (programId !== actualTag.tag_program.id) {
                tagData['tag_program'] = firebase.firestore().collection('programs').doc(allTagPrograms[programIndex].id);
                tagData['program_image'] = allTagPrograms[programIndex].image;
                tagData['program_name'] = allTagPrograms[programIndex].name;
            }

            // Update the tag in Firestore
            await tagRef.update(tagData);

            toast.success('The tag has been updated successfully!');
            fetchUsedFishTags();
            setIsUpdating(false);
            setEditModal(false);
        } catch (error) {
            console.error("Error updating tag:", error);
            setIsUpdating(false);
            toast.error('Could not update tag entry. Please try again.');
        }
    };

    // Handle Delete Tag
    const deleteTag = async () => {
        setIsDeleting(true);

        try {
            const tagRef = firebase.firestore().collection('tags').doc(deleteTagId);
            const catchesRef = firebase.firestore().collection('catches');
            const userRef = firebase.firestore().collection('users');

            // Fetch all catches associated with this tag
            const catchesSnapshot = await catchesRef.where('tag', '==', tagRef).get();

            const batch = firebase.firestore().batch();

            catchesSnapshot.forEach(doc => {
                const catchData = doc.data();
                const catchRef = catchesRef.doc(doc.id);

                // Update user catch/recatch count
                const userDocRef = userRef.doc(catchData.user.id);
                let userUpdateData = { updated_at: serverTimestamp() };

                if (catchData.recatch) {
                    userUpdateData['recatch_count'] = firebase.firestore.FieldValue.increment(-1);
                } else {
                    userUpdateData['catch_count'] = firebase.firestore.FieldValue.increment(-1);
                }

                batch.update(userDocRef, userUpdateData);
                // Mark catch as deleted
                batch.update(catchRef, {
                    updated_at: serverTimestamp(),
                    deleted: true
                });
            });

            // Mark tag as deleted
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: true
            });

            await batch.commit();

            toast.success('The tag and its associated catches have been deleted successfully!');
            fetchUsedFishTags();
            setIsDeleting(false);
            setDeleteModal(false);
        } catch (error) {
            console.error("Error deleting tag:", error);
            setIsDeleting(false);
            toast.error('Could not delete tag entry. Please try again.');
        }
    };

    // Handle Restore Tag
    const restoreUsedTag = async () => {
        setIsRestoringUsed(true);

        try {
            const tagRef = firebase.firestore().collection('tags').doc(restoreTagId);
            const catchesRef = firebase.firestore().collection('catches');
            const userRef = firebase.firestore().collection('users');

            // Fetch all catches associated with this tag
            const catchesSnapshot = await catchesRef.where('tag', '==', tagRef).get();

            const batch = firebase.firestore().batch();

            catchesSnapshot.forEach(doc => {
                const catchData = doc.data();
                const catchRef = catchesRef.doc(doc.id);

                // Update user catch/recatch count
                const userDocRef = userRef.doc(catchData.user.id);
                let userUpdateData = { updated_at: serverTimestamp() };

                if (catchData.recatch) {
                    userUpdateData['recatch_count'] = firebase.firestore.FieldValue.increment(1);
                } else {
                    userUpdateData['catch_count'] = firebase.firestore.FieldValue.increment(1);
                }

                batch.update(userDocRef, userUpdateData);
                // Mark catch as not deleted
                batch.update(catchRef, {
                    updated_at: serverTimestamp(),
                    deleted: false
                });
            });

            // Mark tag as not deleted
            batch.update(tagRef, {
                updated_at: serverTimestamp(),
                deleted: false
            });

            await batch.commit();

            toast.success('The tag and its associated catches have been restored successfully!');
            fetchUsedFishTags();
            setIsRestoringUsed(false);
            setRestoreUsedModal(false);
        } catch (error) {
            console.error("Error restoring tag:", error);
            setIsRestoringUsed(false);
            toast.error('Could not restore tag entry. Please try again.');
        }
    };

    // ---------------------------
    // CRUD MODAL TOGGLES
    // ---------------------------
    const toggleEditModal = (tagId = '', tagIndex = null) => {
        if (editModal) {
            setUpdateTagId('');
            setUpdateTagIndex(null);
        } else {
            setUpdateTagId(tagId);
            setUpdateTagIndex(tagIndex);
        }

        setEditModal(!editModal);
    };

    const toggleDeleteModal = (tagId = '', tagIndex = null) => {
        if (deleteModal) {
            setDeleteTagId('');
            setDeleteTagIndex(null);
        } else {
            setDeleteTagId(tagId);
            setDeleteTagIndex(tagIndex);
        }

        setDeleteModal(!deleteModal);
    };

    const toggleRestoreUsedModal = (tagId = '', tagIndex = null) => {
        if (restoreUsedModal) {
            setRestoreTagId('');
            setRestoreTagIndex(null);
        } else {
            setRestoreTagId(tagId);
            setRestoreTagIndex(tagIndex);
        }

        setRestoreUsedModal(!restoreUsedModal);
    };

    const toggleAddModal = () => {
        setAddModal(!addModal);
    };

    // ---------------------------
    // HELPER FUNCTIONS
    // ---------------------------
    const findNameById = (userId) => {
        const user = allUsersList.find(user => user.id === userId);
        return user ? user.name : "No User Name";
    };

    const findSpeciesById = (speciesId) => {
        const species = allSpecies.find(species => species.id === speciesId);
        return species ? species.name : "No Species";
    };

    // ---------------------------
    // COMPONENT RETURN
    // ---------------------------
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid>
                    <CardTitle>My Fish Tags</CardTitle>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Used Tags</h4>
                                </CardHeader>

                                <CardBody>
                                    {/* Filters and Actions */}
                                    <Row className="g-4 mb-3">
                                        <Col sm="auto">
                                            <div className="d-flex gap-1">
                                                <Button
                                                    color="success"
                                                    onClick={() => toggleAddModal()}
                                                    id="create-btn"
                                                >
                                                    <i className="ri-add-line align-bottom me-1"></i> Add Tag
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col className="d-flex align-items-start">
                                                <Input
                                                    type="search"
                                                    name="search"
                                                    id="search"
                                                    placeholder="Search Tags..."
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                        </Col>
                                        <Col sm="2" md="2">
                                                <Input
                                                    type="select"
                                                    name="species_filter"
                                                    id="species-filter"
                                                    value={selectedSpeciesFilter}
                                                    onChange={(e) => setSelectedSpeciesFilter(e.target.value)}
                                                >
                                                    <option value="">All Species</option>
                                                    {allSpecies.map((spec) => (
                                                        <option key={spec.id} value={spec.id}>
                                                            {spec.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                        </Col>
                                        <Col sm="2" md="2">
                                                <Flatpickr
                                                    id="start-date"
                                                    className="form-control"
                                                    options={{ dateFormat: "Y-m-d", maxDate: endDateFilter ? endDateFilter : undefined }}
                                                    placeholder="Start Date"
                                                    value={startDateFilter}
                                                    onChange={(dates) => setStartDateFilter(dates[0] || null)}
                                                />
                                        </Col>
                                        <Col sm="2" md="2">
                                                <Flatpickr
                                                    id="end-date"
                                                    className="form-control"
                                                    options={{ dateFormat: "Y-m-d", minDate: startDateFilter ? startDateFilter : undefined }}
                                                    placeholder="End Date"
                                                    value={endDateFilter}
                                                    onChange={(dates) => setEndDateFilter(dates[0] || null)}
                                                />
                                        </Col>
                                        <Col sm="1" md="1" className="d-flex align-items-end">
                                            <Button
                                                color="secondary"
                                                onClick={handleApplyFilters}
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-filter"></i> 
                                            </Button>
                                        </Col>
                                        <Col sm="1" md="1" className="d-flex align-items-end">
                                            <Button
                                                color="primary"
                                                onClick={handleDownloadCSV}
                                                style={{ width: '100%' }}
                                            >
                                                <i className="mdi mdi-download"></i> 
                                            </Button>
                                        </Col>
                                    </Row>
                                    {/* End Filters and Actions */}

                                    {/* Tags Table */}
                                    <div className="table-responsive table-card mt-3 mb-1">
                                        {(!isUpdating && !isDeleting && !isRestoringUsed && !isUploading) && (
                                            <table className="table align-middle table-nowrap">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>View</th>
                                                        <th scope="col">Fish Tag Number</th>
                                                        <th scope="col">First Angler</th>
                                                        <th scope="col">Fish Species</th>
                                                        <th scope="col">Created Date</th>
                                                        <th scope="col">Updated Date</th>
                                                        <th scope="col">Catches</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedTags.length > 0 ? displayedTags.map((tag, index) => {
                                                        const actualIndex = currentPage * itemsPerPage + index;
                                                        return (
                                                            <tr key={tag.id}>
                                                                <th scope="row">
                                                                    <Link to={tag.link} className="btn btn-sm btn-success" title="View Tag">
                                                                        <i className="mdi mdi-eye-outline"></i>
                                                                    </Link>
                                                                </th>
                                                                <td>{tag.tag}</td>
                                                                <td>
                                                                    <Link to={tag.user_link} className="fw-medium link-primary">
                                                                        {findNameById(tag.userId)}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={tag.species_link} className="fw-medium link-primary">
                                                                        {findSpeciesById(tag.speciesId)}
                                                                    </Link>
                                                                </td>
                                                                <td>{tag.created}</td>
                                                                <td>{tag.updated}</td>
                                                                <td>{tag.catch_count}</td>
                                                                <td>
                                                                    <span className="badge badge-soft-success text-uppercase">Used</span>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex gap-2">
                                                                        {/* Edit Button */}
                                                                        <Button
                                                                            color="warning"
                                                                            size="sm"
                                                                            onClick={() => toggleEditModal(tag.id, actualIndex)}
                                                                            title="Edit Tag"
                                                                        >
                                                                            <i className="mdi mdi-square-edit-outline"></i>
                                                                        </Button>
                                                                        {/* Delete/Restore Button */}
                                                                        {!tag.deleted ? (
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                                onClick={() => toggleDeleteModal(tag.id, actualIndex)}
                                                                                title="Delete Tag"
                                                                            >
                                                                                <i className="mdi mdi-delete-outline"></i>
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                color="success"
                                                                                size="sm"
                                                                                onClick={() => toggleRestoreUsedModal(tag.id, actualIndex)}
                                                                                title="Restore Tag"
                                                                            >
                                                                                <i className="mdi mdi-history"></i>
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) : (
                                                        <tr>
                                                            <td colSpan="9" className="text-center">No Fish Tags Found!</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        )}

                                        {/* Loader */}
                                        {(isUpdating || isDeleting || isRestoringUsed || isUploading) && (
                                            <div className="d-flex justify-content-center my-5">
                                                <ThreeCircles
                                                    visible={isUpdating || isDeleting || isRestoringUsed || isUploading}
                                                    height="100"
                                                    width="100"
                                                    color="#4192C3"
                                                    ariaLabel="three-circles-loading"
                                                    wrapperStyle={{ margin: "0 auto" }}
                                                    wrapperClass="justify-content-center"
                                                />
                                            </div>
                                        )}

                                        {/* No Results Message */}
                                        {filteredTags.length === 0 && !isUpdating && !isDeleting && !isRestoringUsed && !isUploading && (
                                            <div className="noresult">
                                                <div className="text-center">
                                                    <h5 className="mt-2">No Fish Tags Found!</h5>
                                                    <p className="text-muted mb-0">We could not find any fish tags.</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {/* End Tags Table */}

                                    {/* Pagination Controls */}
                                    {pageCount > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <ReactPaginate
                                                previousLabel={"← Previous"}
                                                nextLabel={"Next →"}
                                                pageCount={pageCount}
                                                onPageChange={handlePageChange}
                                                containerClassName={"pagination"}
                                                previousLinkClassName={"page-link"}
                                                nextLinkClassName={"page-link"}
                                                disabledClassName={"disabled"}
                                                activeClassName={"active"}
                                                pageLinkClassName={"page-link"}
                                                breakLabel={"..."}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* --------------------------- */}
            {/* Modals */}
            {/* --------------------------- */}

            {/* Edit Modal */}
            <Modal isOpen={editModal} toggle={() => toggleEditModal()} centered>
                {!isUpdating && updateTagId && (
                    <form className="tablelist-form" onSubmit={handleUpdateTag}>
                        <ModalHeader toggle={() => toggleEditModal()}>Edit Tag</ModalHeader>
                        <ModalBody>
                            {/* Tag Number */}
                            <FormGroup>
                                <Label htmlFor="edit-tag-number">Tag Number*</Label>
                                <Input
                                    type="text"
                                    name="tag_number"
                                    id="edit-tag-number"
                                    placeholder="#ABCDE1234567890"
                                    maxLength={15}
                                    required
                                    defaultValue={usedTagsList[updateTagIndex]?.tag_number || ''}
                                />
                            </FormGroup>
                            
                            {/* Assigned User */}
                            <FormGroup>
                                <Label htmlFor="edit-angler">Assigned User*</Label>
                                <Input
                                    type="select"
                                    name="first_angler"
                                    id="edit-angler"
                                    required
                                    defaultValue={usedTagsList[updateTagIndex]?.userId || ''}
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.email}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {/* Fish Species (Optional) */}
                            {/* Uncomment if you have species field */}
                            {/* <FormGroup>
                                <Label htmlFor="edit-species">Fish Species*</Label>
                                <Input
                                    type="select"
                                    name="fish_species"
                                    id="edit-species"
                                    required
                                    defaultValue={usedTagsList[updateTagIndex]?.speciesId || ''}
                                >
                                    <option value="">Select Fish Species</option>
                                    {allSpecies.map((species) => (
                                        <option key={species.id} value={species.id}>
                                            {species.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup> */}

                            {/* Program (Optional) */}
                            <FormGroup>
                                <Label htmlFor="edit-program">Tagging Program*</Label>
                                <Input
                                    type="select"
                                    name="tag_program"
                                    id="edit-program"
                                    required
                                    defaultValue={usedTagsList[updateTagIndex]?.tag_program.id || ''}
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map((program) => (
                                        <option key={program.id} value={program.id}>
                                            {program.program_name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => toggleEditModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUpdating}
                                >
                                    Update
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                )}
                {/* Loader for Update */}
                {isUpdating && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUpdating}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Add Modal */}
            <Modal isOpen={addModal} toggle={() => toggleAddModal()} centered>
                {!isUploading && (
                    <form className="tablelist-form" onSubmit={handleCreateTag}>
                        <ModalHeader toggle={() => toggleAddModal()}>Add New Tag</ModalHeader>
                        <ModalBody>
                            {/* Tag Number */}
                            <FormGroup>
                                <Label htmlFor="create-tag-number">Tag Number*</Label>
                                <Input
                                    type="text"
                                    name="tag_number"
                                    id="create-tag-number"
                                    placeholder="#ABCDE1234567890"
                                    maxLength={15}
                                    required
                                />
                            </FormGroup>

                            {/* Assigned User */}
                            <FormGroup>
                                <Label htmlFor="create-angler">Assigned User*</Label>
                                <Input
                                    type="select"
                                    name="first_user"
                                    id="create-angler"
                                    required
                                >
                                    <option value="">Select User Email</option>
                                    {allUsersList.map((user) => (
                                        <option key={user.id} value={user.id}>
                                            {user.email}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {/* Fish Species (Optional) */}
                            {/* Uncomment if you have species field */}
                            {/* <FormGroup>
                                <Label htmlFor="create-species">Fish Species*</Label>
                                <Input
                                    type="select"
                                    name="fish_species"
                                    id="create-species"
                                    required
                                >
                                    <option value="">Select Fish Species</option>
                                    {allSpecies.map((species) => (
                                        <option key={species.id} value={species.id}>
                                            {species.name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup> */}

                            {/* Tagging Program */}
                            {/* <FormGroup>
                                <Label htmlFor="create-program">Tagging Program*</Label>
                                <Input
                                    type="select"
                                    name="tag_program"
                                    id="create-program"
                                    required
                                >
                                    <option value="">Select Tag Program</option>
                                    {allTagPrograms.map((program, index) => (
                                        <option key={program.id} value={index}>
                                            {program.program_name}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup> */}
                        </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => toggleAddModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={isUploading}
                                >
                                    Add Tag
                                </Button>
                            </div>
                        </ModalFooter>
                    </form>
                )}
                {/* Loader for Create */}
                {isUploading && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isUploading}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Delete Modal */}
            <Modal isOpen={deleteModal} toggle={() => toggleDeleteModal()} centered>
                {!isDeleting && (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Delete Used Fish Tag</h4>
                                <p className="text-muted mx-4 mb-0">This will delete the tag along with connected catches or recatches!!!</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => toggleDeleteModal()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                onClick={deleteTag}
                            >
                                Delete
                            </Button>
                        </div>
                    </ModalBody>
                )}
                {isDeleting && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isDeleting}
                                height="100"
                                width="100"
                                color="#ff3d60"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>

            {/* Restore Used Tag Modal */}
            <Modal isOpen={restoreUsedModal} toggle={() => toggleRestoreUsedModal()} centered>
                {!isRestoringUsed && (
                    <ModalBody>
                        <div className="mt-2 text-center">
                            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                                <h4>Restore Used Fish Tag</h4>
                                <p className="text-muted mx-4 mb-0">This will restore the tag along with connected catches or recatches!!!</p>
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                            <Button
                                type="button"
                                color="light"
                                onClick={() => toggleRestoreUsedModal()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="button"
                                color="success"
                                onClick={restoreUsedTag}
                            >
                                Restore
                            </Button>
                        </div>
                    </ModalBody>
                )}
                {isRestoringUsed && (
                    <ModalBody>
                        <div className="d-flex justify-content-center my-5">
                            <ThreeCircles
                                visible={isRestoringUsed}
                                height="100"
                                width="100"
                                color="#4192C3"
                                ariaLabel="three-circles-loading"
                                wrapperStyle={{ margin: "0 auto" }}
                                wrapperClass="justify-content-center"
                            />
                        </div>
                    </ModalBody>
                )}
            </Modal>
        </React.Fragment>
    );

};

export default UsedTags;